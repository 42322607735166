import axios from "axios";
import moment from "moment";
import Auth from "../store/modules/Auth";
import router from "../router";
import { iziToastFunctions } from "../js/IziToastFunctions";

const ApiMunzen = axios.create({
  baseURL: import.meta.env.VITE_MUNZEN_API_URL,
});

// declare an ajax request's cancelToken (globally)
let ajaxRequest = null;

ApiMunzen.interceptors.request.use(
  async (config) => {
    //we check if there is an access token in the local storage to make append it to the auth header
    if (localStorage.getItem("accessToken")) {
      await refreshToken();
    }

    config.headers.authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ApiMunzen.interceptors.response.use(
  (response) => {
    //perform the manipulation here and change the response object
    return response;
  },
  async (error) => {
    let apiErrorClientMessage = null;

    if (error.response.data.persistentErrorMessage) {
      //Comúnmente estos son errores del servidor o errores en donde se quiere dar más énfasis a que el usuario los revise

      let errorMessage = error.response.data.dataError;

      if (error.response.data.errorTrackingUUID) {
        errorMessage += `<br>Hemos sido notificados del problema y lo revisaremos para solucionarlo lo antes posible. <br>Puedes ponerte en contacto con nuestro soporte técnico proporcionando el siguiente ID de error: <strong>${error.response.data.errorTrackingUUID}</strong>.`;
      }

      errorMessage = errorMessage.replace(
        /<strong>(.*?)<\/strong>/g,
        '<span class="extra-bold-font">$1</span>'
      );
      errorMessage = errorMessage.replace("baseURL", window.location.origin);

      iziToastFunctions.persistentErrorMessage(errorMessage);
    } else {
      if (error.response.data.showErrorToClient) {
        //Se muestra el tipo de error por default con izzitoast
        iziToastFunctions.error(error.response.data.dataError);
        console.log("Error info: ", error.response.data);
      } else {
        console.log("Error info: ", error);

        if (error.response.status === 403) {
          //Error generado por intentar acceder a un recurso sin autorización o perteneciente a otro usuario
          iziToastFunctions.error("Inicie sesión nuevamente, por favor.");
          console.log("unauthorized, logging out ...");
          console.log("error", error);
          console.log("error response", error.response);

          localStorage.removeItem("accessToken");
          localStorage.removeItem("user");
          localStorage.removeItem("expirationDate");
          Auth.state.user = null;
          Auth.state.token = null;
          await router.push({ name: "login" });
        } else if (
          error.response.status >= 400 &&
          error.response.status <= 499
        ) {
          //Si es algún error del cliente solo se pasa el mensaje para que el componente lo muestre de la forma deseada
          if (error.response.data.dataError) {
            apiErrorClientMessage = error.response.data.dataError;
          } else {
            apiErrorClientMessage = error.response.statusText;
          }
        }
      }
    }

    error.apiErrorClientMessage = apiErrorClientMessage;

    return Promise.reject(error);
  }
);

export default ApiMunzen;

/**
 *
 * @returns {Promise<void>}
 */
async function refreshToken() {
  const now = moment();
  const expirationDate = localStorage.getItem("expirationDate");

  //refresh token if it has expired
  if (now.isSameOrAfter(moment(expirationDate, "x"), "second")) {
    //Si ya existe una petición de refresh token hacemos que el request se espere 1 segundo para
    //que el refresh token terminé de solicitar el nuevo token al API.
    //Al terminar el tiempo la petición original continua con el nuevo token
    if (ajaxRequest) {
      await new Promise((r) => setTimeout(r, 1000));
      return;
    }

    // creates a new token for upcoming ajax (overwrite the previous one)
    //Es usado para no repetir peticiones de refresh token al API esto puede pasar en peticiones
    //tipo Promise.all en donde se ejecutan muchas peticiones en paralelo y si el token ya expiró, lanzará un error
    //al muchos recursos intentar actualizar el token
    ajaxRequest = axios.CancelToken.source();

    axios.defaults.headers.authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    await axios
      .get(import.meta.env.VITE_MUNZEN_API_URL + "/users/refresh-token", {
        cancelToken: ajaxRequest.token,
      })
      .then((resp) => {
        const now = new Date();

        const expirationDate = now.getTime() + resp.data.expires_in * 1000;
        localStorage.setItem("expirationDate", expirationDate.toString());

        Auth.state.token = resp.data.token;
        localStorage.setItem("accessToken", resp.data.token);

        ajaxRequest = null;
      })
      .catch(async (error) => {
        console.log("error", error);
        console.log("error Refresh token", error.response);
        console.log("token", `Bearer ${localStorage.getItem("accessToken")}`);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        localStorage.removeItem("expirationDate");
        Auth.state.user = null;
        Auth.state.token = null;
        await router.push({ name: "login" });
      });
  }
}
